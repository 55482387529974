import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'

import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import styles from './../styles/404.module.css'

const NotFoundPage = () => {
  const [lottieLoaded, setLottieLoaded] = useState(false)
  useEffect(() => {
    require('@lottiefiles/lottie-player')
    setLottieLoaded(true)
  }, [])

  return (
    <Layout>
      <Seo title='404: Not found' />
      <div className={styles.container}>
        <h1 className={responsiveTitle1}>404: Page not found</h1>
        <div className={styles.animation}>
          {lottieLoaded &&
            // ? https://lottiefiles.com/web-player?lottie_url=https%3A%2F%2Fassets4.lottiefiles.com%2Fpackages%2Flf20_zxliqmhr.json
            <lottie-player
              autoPlay
              loop
              playsInline
              mode='normal'
              src='https://assets4.lottiefiles.com/packages/lf20_zxliqmhr.json'
              style={{ width: 300, height: 300, margin: '2rem auto' }}
            />}
        </div>

        <Link to='/' className={styles.homeLink}>
          <FaArrowLeft />
          Go back home
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
